<template>
  <StandardIconBloc
    icon="search"
    backColor="rgb(232,232,232)"
    frontColor="rgb(88,88,88)"
  >
    <slot></slot>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "SearchBloc",
  components: { StandardIconBloc },
};
</script>
