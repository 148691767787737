<template>
  <StandardIconBloc
    icon="lightning-charge-fill"
    backColor="rgb(255,235,230)"
    frontColor="rgb(222,53,11)"
  >
    <slot></slot>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "FlashBloc",
  components: { StandardIconBloc },
};
</script>
