<template>
  <StandardIconBloc
    icon="code-slash"
    backColor="rgb(232,232,232)"
    frontColor="rgb(88,88,88)"
  >
    <pre><slot></slot></pre>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "CodeBloc",
  components: { StandardIconBloc },
};
</script>

<style scoped>
pre {
  margin: 0;
  white-space: normal;
}
</style>
