<template>
  <StandardIconBloc
    icon="info-circle-fill"
    backColor="rgb(222,235,255)"
    frontColor="rgb(0,82,204)"
  >
    <slot></slot>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "InfoBloc",
  components: { StandardIconBloc },
};
</script>
