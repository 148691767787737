<template>
  <section class="markdown">
    <div v-for="(bloc, index) in blocs" :key="index">
      <div
        v-if="bloc.type === 'markdown' && bloc.contenu"
        v-html="bloc.contenu"
      ></div>
      <component
        v-if="bloc.type !== 'markdown' && bloc.contenu"
        :is="bloc.component"
      >
        <div v-html="bloc.contenu"></div>
      </component>
    </div>
  </section>
</template>

<script>
import CodeBloc from "../blocs/CodeBloc.vue";
import DangerBloc from "../blocs/DangerBloc.vue";
import ErrorBloc from "../blocs/ErrorBloc.vue";
import FlashBloc from "../blocs/FlashBloc.vue";
import InfoBloc from "../blocs/InfoBloc.vue";
import NoteBloc from "../blocs/NoteBloc.vue";
import QuestionBloc from "../blocs/QuestionBloc.vue";
import QuoteBloc from "../blocs/QuoteBloc.vue";
import SearchBloc from "../blocs/SearchBloc.vue";
import SuccessBloc from "../blocs/SuccessBloc.vue";
import TaskBloc from "../blocs/TaskBloc.vue";
import WarningBloc from "../blocs/WarningBloc.vue";
import StrapiService from "../../services/strapi.service";
export default {
  name: "StrapiMarkdownComponent",
  props: ["item"],
  components: {
    CodeBloc,
    DangerBloc,
    ErrorBloc,
    FlashBloc,
    InfoBloc,
    NoteBloc,
    QuestionBloc,
    QuoteBloc,
    SearchBloc,
    SuccessBloc,
    TaskBloc,
    WarningBloc,
  },
  data() {
    return {
      blocTypes: [
        {
          type: "code",
          component: "CodeBloc",
          startTag: "$code$",
          endTag: "$/code$",
        },
        {
          type: "danger",
          component: "DangerBloc",
          startTag: "$danger$",
          endTag: "$/danger$",
        },
        {
          type: "error",
          component: "ErrorBloc",
          startTag: "$error$",
          endTag: "$/error$",
        },
        {
          type: "flash",
          component: "FlashBloc",
          startTag: "$flash$",
          endTag: "$/flash$",
        },
        {
          type: "info",
          component: "InfoBloc",
          startTag: "$info$",
          endTag: "$/info$",
        },
        {
          type: "note",
          component: "NoteBloc",
          startTag: "$note$",
          endTag: "$/note$",
        },
        {
          type: "question",
          component: "QuestionBloc",
          startTag: "$question$",
          endTag: "$/question$",
        },
        {
          type: "quote",
          component: "QuoteBloc",
          startTag: "$quote$",
          endTag: "$/quote$",
        },
        {
          type: "search",
          component: "SearchBloc",
          startTag: "$search$",
          endTag: "$/search$",
        },
        {
          type: "success",
          component: "SuccessBloc",
          startTag: "$success$",
          endTag: "$/success$",
        },
        {
          type: "task",
          component: "TaskBloc",
          startTag: "$task$",
          endTag: "$/task$",
        },
        {
          type: "warning",
          component: "WarningBloc",
          startTag: "$warning$",
          endTag: "$/warning$",
        },
      ],
      blocs: [],
    };
  },
  computed: {
    // C'est une solution un peu dégueulasse pour convertir les URL relatives en URL absolues...
    // Ne me jugez pas, Strapi est très mal foutu sur ce point (j'ai tout tenté avant d'en arriver là)
    /*
     * Explication courte de ce désastre :
     * - Si on ne précise pas l’url publique de Strapi dans config/server.js, les éditeurs de texte enrichi ne sont pas capables de fournir des liens absolus pour les assets de la médiathèque (dont les images par exemple)
     *   Cela ne marche donc que si Strapi occupe le port par défaut (HTTPS) sur le reverse proxy, ce qui n’est pas notre cas
     * - Si on précise l’url publique dans config/server.js, même en utilisant une directive env(...), le panneau d'administration de Strapi récupère l'URL à la compilation !
     *   Il y a une bonne raison à ça, la même qui m'a poussé à créer un fichier config.json pour le front, mais quand même : changement d’environnement => changement d’URL publique => recompilation nécessaire
     * Comme c'est hors de question de créer une image Docker différente pour le Strapi de chaque environnement
     * Et que je n'ai pas le temps de séparer admin panel et backend de Strapi
     * J'ai opté pour une interception des URL directement en front avant affichage
     * Plus d’infos sur le problème ici https://github.com/strapi/strapi/issues/12129
     */
    absolutePathsItem() {
      return this.item
        ? this.item
            // Interception des attributs HTML type src ou href
            .replaceAll(
              `="/uploads/`,
              `="${StrapiService.getBaseUrl()}/uploads/`
            )
            // Interception spécifique pour l'attribut srcset
            .replaceAll(`,/uploads/`, `,${StrapiService.getBaseUrl()}/uploads/`)
        : "";
    },
  },
  mounted() {
    this.loadBlocs();
  },
  methods: {
    loadBlocs() {
      let items = [];
      let startIndex = 0;

      // Découper le markdown en blocs
      let bloc = this.findNextBloc(startIndex);
      let contenu = null;
      while (bloc !== null) {
        // Traiter le markdown avant le prochain bloc
        if (startIndex < bloc.startIndex) {
          contenu = this.getBlocContent(startIndex, bloc.startIndex);
          if (contenu) {
            items.push({
              type: "markdown",
              startIndex: startIndex,
              endIndex: bloc.startIndex,
              contenu: contenu,
            });
          }
        }

        // Stacker le bloc courant et passer au suivant
        if (bloc.contenu) {
          items.push(bloc);
        }
        startIndex = bloc.endIndex + bloc.endTag.length;
        bloc = this.findNextBloc(startIndex);
      }

      // Traiter tout le reste comme du markdown
      if (startIndex < this.absolutePathsItem.length) {
        contenu = this.getBlocContent(
          startIndex,
          this.absolutePathsItem.length
        );
        if (contenu) {
          items.push({
            type: "markdown",
            startIndex: startIndex,
            endIndex: this.absolutePathsItem.length,
            contenu: contenu,
          });
        }
      }
      this.blocs = items;
    },
    findNextBloc(fromIndex) {
      let startIndexes = this.blocTypes
        // Trouver la prochaine occurrence de chaque bloc
        .map((b) => [b, this.absolutePathsItem.indexOf(b.startTag, fromIndex)])
        // Filtrer les blocs non trouvés
        .filter((i) => i[1] >= 0)
        // Trier par ordre d'occurrence
        .sort((i1, i2) => i1.index - i2.index);
      if (startIndexes.length === 0) {
        return null;
      }

      // Récupérer le contenu du bloc
      let blocType = startIndexes[0][0];
      let startIndex = startIndexes[0][1];
      let endIndex = this.absolutePathsItem.indexOf(
        blocType.endTag,
        startIndex
      );
      endIndex = endIndex < 0 ? this.absolutePathsItem.length : endIndex;
      let contenu = this.getBlocContent(startIndex, endIndex);
      return {
        ...blocType,
        startIndex: startIndex,
        endIndex: endIndex,
        contenu: contenu,
      };
    },
    getBlocContent(startIndex, endIndex) {
      let content = this.absolutePathsItem.slice(startIndex, endIndex);
      this.blocTypes.forEach((b) => {
        content = content.replace(b.startTag, "");
        content = content.replace(b.endTag, "");
      });
      return content;
      // Suppression de l'interprétation Markdown
      // return marked(content);
    },
  },
};
</script>
