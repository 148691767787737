<template>
  <StandardIconBloc
    icon="check-circle-fill"
    backColor="rgb(227,252,239)"
    frontColor="rgb(0,135,90)"
  >
    <slot></slot>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "SuccessBloc",
  components: { StandardIconBloc },
};
</script>
