<template>
  <StandardIconBloc
    icon="file-text-fill"
    backColor="rgb(234,230,255)"
    frontColor="rgb(82,67,170)"
  >
    <slot></slot>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "NoteBloc",
  components: { StandardIconBloc },
};
</script>
