<template>
  <StandardIconBloc
    icon="exclamation-triangle-fill"
    backColor="rgb(255,250,230)"
    frontColor="rgb(255,153,31)"
  >
    <slot></slot>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "WarningBloc",
  components: { StandardIconBloc },
};
</script>
