<template>
  <StandardIconBloc
    icon="chat-quote-fill"
    backColor="rgb(232,232,232)"
    frontColor="rgb(88,88,88)"
  >
    <em><slot></slot></em>
  </StandardIconBloc>
</template>

<script>
import StandardIconBloc from "./StandardIconBloc.vue";
export default {
  name: "QuoteBloc",
  components: { StandardIconBloc },
};
</script>
